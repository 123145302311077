.item {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    border: 1px solid #dcdcdc;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
  }
  
  .handle {
    font-size: 18px;
    margin-right: 8px;
    cursor: grab;
  }
  
  .text {
    flex: 1;
  }
  