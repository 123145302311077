.Navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 0;
    font-family: 'Mulish';
    z-index: 2000;
    height: 60px;
    background: #272727;
    position: fixed;
    padding-top: 10px;
    padding-left: 3.5em;
    padding-right: 2em;
    padding-bottom: 10px;
}

.icons {
    height: 20px;
    width: 20px;
    margin-top: 4px;
    margin-right: 4px;
}

.link-wrapper {
    display: inline-flex;
    margin-right: 40px;
    margin-top: 6px;
}