.loveguard-title {
    font-family: 'Cinzel Decorative';
    z-index: 100;
    font-size:170px;
    top: 200px;
    position: absolute;
}

.loveguard-subtitle {
    font-family: 'Cinzel Decorative';
    z-index: 100;
    font-size: 100px;
    top: 520px;
    position: absolute;
    color: pink;
}

.gadget-container {
    width: 60%;
    background: #F5F5F5;
    z-index: 1000;
    text-align: left;
}

.gadget-container-inner {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: -200px;
    flex-wrap: wrap;
    flex-direction: row;
}

.gadget {
    max-width: 400px;
    min-height: 300px;
    margin-bottom: 40px;
    background: white;
    color: black;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.04);
}

.gadget-inner {
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.section {
    color: #F34848;
    text-align: start;
    font-weight: bold;
    
}

.card {
    width: auto;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    padding: 20px;
    text-align: left;
    margin-top: 20px;
}

.cardPic {
    width: 280px;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cardPic2 {
    width: auto;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cardPic3 {
    width: auto;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 1.5em;
}



.cardTransparent {
    background: #272727cc;
    border-radius: 16px;
    text-align: left;
    padding: 28px 40px 40px 40px;
    margin-top: -500px;
    align-content: normal;
    width: auto;
    margin-left: 4em;
    margin-right: 18em;  
}


.cardTransparentMobile {
    background: #272727cc;
    border-radius: 16px;
    text-align: left;
    padding: 28px 32px 32px 32px;
    margin-top: -600px;
    align-content: normal;
    width: auto;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
}

.cardTransparentInner {
    background: #272727cc;
    border-radius: 16px;
    text-align: left;
    padding: 28px 32px 48px 32px;
    align-content: normal;
    
}



.cardTransparentInnerQuiz {
    background: #272727cc;
    border-radius: 16px;
    text-align: left;
    padding: 16px 16px 16px 16px;
    align-content: normal;
    
}

.loveguardBackground {

    background-image: url('../../Assets/motor.png');
    background-size: cover;
    min-height: 380px;
    height: 100%;
    border-radius: 0px 16px 16px 0px;
}

.part {
    background: #F5F5F5;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 20px;
}

.jazykBG {

    display: flex;
    background-size: cover;
    background-position-y: center;
    width: 20%;
    height: 80px;
    text-align: center;
    flex: wrap;
    justify-content: space-around;
}
.jazykBG-mobile {

    background-size: cover;
    background-position-y: center;
    width: 100%;
    height: 140px;
    text-align: center;
    padding: 24px;
}

.flexRow {
    display: flex;
    justify-content:space-evenly;
    flex-wrap: nowrap;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    background-color: #F5F5F5;
}
.flexRowNoPX {
    display: flex;
    justify-content:space-evenly;
    flex-wrap: nowrap;
    padding-top: 1em;
    background-color: #F5F5F5;
}

.flexRow4 {
    display: flex;
    justify-content:space-between;
    flex-wrap: nowrap;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    background-color: #F5F5F5;
}

.flexRow2 {
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    background-color: #F5F5F5;
}


.flexRow3 {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    padding-left: 3em;
    background-color: #F5F5F5;
}

.flexRowMatch {
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    background-color: #F5F5F5;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 2em;
}

.flexRowClose {
    display: flex;
    justify-content: center ;
    flex-wrap: wrap;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    background-color: #F5F5F5;
}


.cardPicture {
    max-width: auto;
    margin-top: 2em;
}
.cardPictureMobile {
    max-width: auto;
    margin-top: 2em;
   
}

.cardPictureQuiz {
    max-width: 280px;
    margin-top: 2em;
    background-color: #272727;
    padding: 20px;
    color: white;
    border-radius: 16px;
    box-shadow: 2px 4px 8px #27272760;
  
}

.cardReview {
    min-width: 340px;
    max-width: 500px;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 60px;
    margin-right: 20px;
    margin-left: 20px;
}

.cardPicQuiz {
    max-width: 50%;
    min-width: 340px;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cardPicQuizDesk {
    max-width: 50%;
    min-width: 340px;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px 0px 0px 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cardFunnelMobile {
    max-width: 50%;
    min-width: 340px;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px 16px 16px 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cardFunnelMobile2 {
    position: relative;
    left: 25%;
    max-width: 50%;
    min-width: 340px;
    height: auto;
    background: #272727;
    color: white;
    border-radius: 16px 16px 16px 16px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    margin-top: 80px;
    margin-bottom: 20px;
}

.loveguardBackgroundFunnel {
    height: 100%;
    border-radius: 16px 16px 16px 16px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.cardTransparentInnerFunnel {
    background: #272727cc;
    text-align: left;
    align-content: normal;
}


.cardTransparentMobile {
    background: #272727cc;
    border-radius: 16px;
    text-align: left;
    padding: 28px 32px 32px 32px;
    margin-top: -600px;
    align-content: normal;
    width: auto;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
}

.quizQuestion {
    background: #F5F5F5;
    color: white;
    height: auto;
    min-height: 100px;
    border-radius: 8px;
    box-shadow: 4px 4px 16px 4px rgba(0, 0, 0, 0.24);
    text-align: center;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 32px;
}

.column:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }