.back {
    background-image: url('../../Assets/LoveguardBanner.png');
    height: 100%;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 100%;
    padding: 0px;
}

.background-image {
    background-image: url('../../Assets/TestBackgroundPicture.png');
    background-size: cover;
    background-position: stretch;
    align-items: center;
    width: 100%;
    /* Set the width of the background image container */
    height: 100%;
    /* Set the height of the background image container */
    /* Occupy the full height of the container */
}

.background-image-mobile {
    background-image: url('../../Assets/TestBackgroundPictureMobile.png');
    background-size: cover;
    background-position: stretch;
    align-items: center;
    width: 100%;
    /* Set the width of the background image container */
    height: 100%;
    /* Set the height of the background image container */
    /* Occupy the full height of the container */
}


.login-form {
    width: 25%;
    min-width: 360px;
    background-color: #272727F3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 1em;


    /* 100vw refers to the full width of the viewport. */
    /* Add styles to position and style your login form */
}

.loginButton {
    background: #3B579D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    padding: 10px;
    margin: 10px;
}
.fb-text{
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 2px;
    margin-right: 20px;

}